import { Link } from 'gatsby'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
// import IconText from '../components/IconText'
import fb from '../images/facebook.svg'
import google from '../images/google-plus.svg'
import linkedin from '../images/linkedin.svg'
import Logo from '../images/logo.png'
import mail from '../images/mail.svg'
import map from '../images/map.svg'
import phone from '../images/phone.svg'
import youtube from '../images/youtube-icon.svg'

const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container fluid>
          <Row>
            <Col lg={5} xl={5}>
              <div className="footer__col-left">
                <Image src={Logo} fluid className="footer__logo" alt="logo" />
                <p className="footer__tag-line" className="footer__tag-line">
                  RoadLINX is a proud provider of cross-border freight shipping
                  in Canada and the U.S.
                </p>
                <div className="footer__wrapper">
                  <div className="footer__sub-wrapper">
                    <div className="footer__ter-wrapper">
                      <Image
                        className="footer__icon"
                        src={phone}
                        fluid
                      />
                      <a href="tel:905-760-1141" className="footer__heading">
                        Phone 905-760-1141
                      </a>
                    </div>
                    {/* <IconText icon={phone} text="Phone 905-760-1141" /> */}
                    <div className="footer__ter-wrapper">
                      <Image className="footer__icon" src={map} fluid />
                      <div className="footer__heading">Office Address</div>
                      <div className="footer__address">
                        501 Rowntree Dairy Road, Unit #4, Woodbridge, ON L4L 8H1
                      </div>
                    </div>
                    <div className="footer__ter-wrapper">
                      <Image className="footer__icon" src={mail} fluid />
                      {/* <div className="footer__heading"> */}
                      <a
                        href="mailto:ian@roadlinx.com"
                        className="footer__heading"
                      >
                        ian@roadlinx.com
                      </a>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="footer__sub-wrapper">
                    <div className="footer__ter-wrapper">
                      <Image
                        className="footer__icon"
                        src={phone}
                        fluid
                      />
                      <div className="footer__heading">Fax 905-760-1146</div>
                    </div>
                    <div className="footer__ter-wrapper">
                      <Image className="footer__icon" src={map} fluid />
                      <div className="footer__heading">Warehouse Address</div>
                      <div className="footer__address">
                        6221 Highway 7 West, Unit #3, Vaughan, ON L4H 0K8
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7} xl={7}>
              <div className="footer__col-right">
                <div className="footer__sub-wrapper">
                  <div className="footer__sub-heading">Our Services</div>
                  <div className="footer__text">
                    <Link to="/services/dry-van-freight/">Dry Van Shipping</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/services/truckload-ftl/">FTL Shipping</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/services/reefer-freight/">
                      Refrigerated Shipping
                    </Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/services/flatbed-freight/">
                      Flatbed Trailer Rental
                    </Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/services/warehouse-storage/">
                      Warehousing Storage
                    </Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/services/trade-show/">Trade Show Shipping</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/services/expedite-services/">
                      Expedite Services
                    </Link>
                  </div>
                </div>
                <div className="footer__sub-wrapper">
                  <div className="footer__sub-heading">Our Equipment</div>
                  <div className="footer__text">
                    <Link to="/equipment/dry-van/">Dry Van</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/equipment/temperature-controlled/">
                      Temperature Controlled
                    </Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/equipment/flatbeds/">Flatbeds</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/equipment/intermodal-containers/">
                      Intermodal Containers
                    </Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/equipment/storage-trailers/">
                      Storage Trailers
                    </Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/equipment/chassis-types/">Chassis Types</Link>
                  </div>
                </div>
                <div className="footer__sub-wrapper">
                  <div className="footer__sub-heading">Roadlinx Inc.</div>
                  <div className="footer__text">
                    <Link to="/">Home</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/about-us/">About Us</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/equipment/">Equipment</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/blog/">Free Resources</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/careers/">Careers</Link>
                  </div>
                  <div className="footer__text">
                    <Link to="/contact-us/">Contact Us</Link>
                  </div>
                </div>
              </div>

              <div className="footer__icons-wrapper">
                <a
                  href="https://www.facebook.com/RoadLINX/"
                  className="footer__link"
                >
                  <Image src={fb} className="footer__social-icon" />
                </a>

                <a
                  href="https://www.linkedin.com/company/roadlinx/about/"
                  className="footer__link"
                >
                  <Image src={linkedin} className="footer__social-icon" />
                </a>
                <a
                  href="https://plus.google.com/u/0/108831081307274188203"
                  className="footer__link"
                >
                  <Image src={google} className="footer__social-icon" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCw-RZcawMBC2LpjkdMnV7FA/featured"
                  className="footer__link"
                >
                  <Image src={youtube} className="footer__social-icon" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="footer__bottom">
        © 2021 Roadlinx Inc. Web Design & &nbsp;
        <a href="https://2marketing.com/" target="_blank">SEO Toronto</a>
        {' '}
        &nbsp; by 2Marketing.com
      </div>
    </>
  )
}

export default Footer
